import Blueprint from "$blueprint/js/blueprint";
import Reveal from "$blueprint/js/reveal/index";
import Forms from "$blueprint/js/forms/index";

Blueprint().then(blueprint => {
    if (blueprint.isWebsite){
        Reveal.create().then(Reveal.swup(blueprint.swup));
        Forms.create().then(Forms.swup(blueprint.swup));
    }
});
