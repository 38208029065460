import lazySizes from 'lazysizes';
import alpine from "alpinejs";
import Swup from "swup";
import SwupScrollPlugin from "@swup/scroll-plugin";
import SwupFadeTheme from "@swup/fade-theme";

export default (configuration={}) => {

    var isCMS = !!document.querySelector("html.cms");
    var isWebsite = !!document.querySelector("html.website");

    if (isWebsite){
        window.swup = new Swup({
            containers: ["#page"],
            plugins: [
                new SwupFadeTheme({
                    mainElement: "#page",
                }),
                new SwupScrollPlugin({
                    // doScrollingRightAway: true,
                    scrollFriction: 0.4,
                    scrollAcceleration: 0.2,
                }),
            ],

            ...(configuration.swup ? configuration.swup : {}),
        });
    }

    return new Promise((resolve, reject) => {
        resolve({
            isCMS,
            isWebsite,
            swup: window.swup,
        });
    });
}
